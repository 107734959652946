.shipping {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 3fr 2fr;
	padding: 2rem 1rem;
	& .shipping-panel {
		display: grid;
		grid-gap: 1rem;
		& > span:nth-child(1) {
			justify-self: start;
			font-size: 12px;
		}
		& .shipping-info {
			border: 1px solid #ccc;
			border-radius: 5px;
			padding-block: .5rem;
			display: grid;
			align-items: center;
			grid-template-columns: minmax(auto, 100px) 1fr minmax(auto, 100px);
			font-size: 14px;
			& hr {
				grid-column: span 3;
				height: 1px;
				width: 90%;
				justify-self: center;
				margin: 0;

			}
			& > a {
				text-decoration: none;
				color: #ccc;
				font-size: 12px
			}
		}
		& .shipping-method {
			display: grid;
			& > span:nth-child(1) {
				justify-self: start;
			}
			& .shipping-items {
				border: 1px solid #ccc;
				border-radius: 5px;
				display: grid;
				grid-gap: 1rem;
				align-items: center;
				padding: .5rem 1rem;
				grid-template-columns: auto 1fr auto;
				& > span {
					justify-self: start;
					margin: 0;
				}
			}
		}
	}
}


.payment-method {
    display: grid;
    & > span:nth-child(n+1) {
        justify-self: start;
    }
    & > span:nth-child(2) {
        font-size: 12px;
    }
    .credit-card-options {
        display: grid;
        grid-gap: .25rem;
        grid-template-columns: 1fr repeat(4, auto);
        border: 1px solid #ccc;
        border-bottom: none;
        padding: .5rem 1rem;
        border-radius: 5px 5px 0 0;
        & > span:nth-child(1) {
            justify-self: start;
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.payment-items {
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 0 0 5px 5px;
    background-color: #fafafa;
    input {
        padding: .5rem 1rem;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .CardField-input-wrapper {
        display: grid;
    }
}