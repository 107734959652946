$pink: #FFD9D2;

.topHeader {
    @media (max-width: 767px) {
        font-size: 3.5vw;
        ul {
            display: none;
        }
    }
        
    padding-block: .5rem;
    background-color: $pink;
    color: #FFF;
    text-shadow: 1px 1px 2px #000;
    display: grid;
    grid-template-columns: 1fr auto;
    padding-inline: 2em;
    & a {
        color: #FFF;
        text-decoration: none;
        &:hover {
            color: #333;
            text-decoration: none;
        }
    }
    & ul {
        display: grid;
        grid-gap: calc(100vw * .01);
        grid-template-columns: repeat(7, auto);
    }
}
.topNavbar {
    display: grid;
    .nav-main-menu {
        @media (max-width: 767px) {
            ul {
                grid-gap: 0;
            }
            li:not(:nth-child(n+6)) {
                display: none;
            }
            .hamburger {
                display: block;
            }
        }
        width: 100%;
        margin-inline: auto;
        z-index: 2;
        display: grid;
        padding: 0 2em;
        grid-template-columns: auto 1fr;
        & .logo {
            height: 65px;
        }
        &:hover {
            background-color: #FFF;
            opacity: .7;
            & li:not(:nth-child(n+7)):after {
                content: '';
                border-bottom: solid 1px #000;  
                transform: scaleX(0);  
                transition: transform 250ms ease-in-out;
            }
            & li:hover:after { 
                transform: scaleX(1); 
                transform-origin:  0% 100%;
            }

            & a {
                color: #333;
                text-shadow: none;
                font-weight: 900;
                &:hover {
                    color: $pink;
                    text-shadow: 1px 1px 1px #333;
                }
            }
        }
        & ul {
            justify-self: end;
            display: grid;
            grid-column-gap: calc(100vw * .03);
            grid-template-columns: repeat(8, auto);
            & li {
                display: grid;
                font-size: 1em;
                & a {
                    align-self: center;
                    text-decoration: none;
                    color: #FFF;
                    text-shadow: 1px 1px 2px #000;
                }
            }
        }
        & .hamburger {
            display: none;
        }
    }
    & .hamburger {
        align-self: center;
        cursor: pointer;
    }
    & .hamburger > span {
        z-index: 3;
        display: block;
        width: 25px;
        height: 3px;
        margin: 2px auto;
        transition: all 0.3s ease-in-out;
        background-color: grey;
    }
    & .nav-side-menu {
        z-index: 22;
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        height: 100vh;
        background: #FFF !important;
        & .icon-close {
            padding: 20px;
            position: absolute;
            top: 10px;
            right: 15px;
            color: #000;
            cursor: pointer;
        }
    }
    & .nav-side-menu-content {
        padding-top: 2rem;
        text-align: center;
        & img {
            width: 50%;
            margin-left: 5%;
        }
        & ul {
            list-style: none;
            font-size: 24px;
            display: grid; grid-template-rows: repeat(10, 1fr);
            grid-gap: 1rem;
            & a {
                color: #000;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .topHeader {
        font-size: 3.5vw;
        & ul {
            display: none;
        }
    }
    .topNavbar .nav-main-menu {
        display: flex;
        justify-content: space-between;
        & ul {
            grid-gap: 0;
        }
        padding: 0 1rem;
        & .hamburger {
            display: block;
        }
    }
}