@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');
$pink: #FFD9D2;

* {
    margin: 0;
    padding: 0;
    line-height: 1.4;
    box-sizing: border-box;
    font-family: 'Marcellus', serif !important;
}
body {
    width: 100%;
}
ul {
    padding: 0;
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
img {
    width: 100%;
}

.hide {
    display: none;
}
