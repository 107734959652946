$pink: #FFD9D2;

.reviews {
	display: grid;
	grid-auto-flow: row dense;
	align-items: start;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fit, minmax(160px, 210px));
	& a {
		text-decoration: none !important;
		color: #333 !important;
		&:hover {
			color: $pink !important;
		}
	}
	& .card-content {
		text-align: left;
		padding-inline: 1em;
	}
	li {
		&:nth-child(2) {
		font-weight: 900;
		}
		&:nth-child(3) {
		font-size: 10px;
		}
	}
}