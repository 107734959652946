$pink: #FFD9D2;

button {
    padding: 0.5em 1em;
    border: none; 
    color: #FFF;
    background-color: #000;
	a {
      color: #FFF;
    }
}

.form-submit {
	height: 42px;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(2, auto);
	& > a {
		color: #333;
		align-self: center;
		justify-self: start;
		font-size: 12px;
		text-decoration: none;
	}
}

.overlay {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background:rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}


.wrapper {
	position: relative;
	border-top: 1px solid $pink;
	border-bottom: 1px solid $pink;
    margin: 1rem auto 1rem auto;
	padding: 2rem 1rem;
    max-width: 50rem;
    text-align: center;
	min-height: 45vh;
	// display: grid;
	
	.title {
		left: 50%;
		transform: translate(-50%);
		position: absolute;
		justify-self: center;
		background: #fff;
		font-size: 14px;
		color: white;
		text-shadow: -1px -1px 0 $pink, 1px -1px 0 $pink, -1px 1px 0 $pink, 1px 1px 0 $pink;
		top: -11px;
		padding-inline: 1rem;
	}
}


@media (max-width: 768px) {
	.cart-info {
		order: -1;
	}
}