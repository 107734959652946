@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);

.rating {
    & > input { 
        display: none; 
    }
    & > label:before { 
        margin-inline: 5px;
        font-size: 1.5em;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f005";
    }
    & > .half:before { 
        content: "\f089";
        position: absolute;
    }
    & > label { 
        color: #ddd; 
        float: right; 
    }
} 

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label { color: #FFD700;  } /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label { color: #FFED85;  } 