.cart-info {
	background-color: #eee;
	border-radius: 5px;
	padding: 2rem 1rem;
	& .MuiBadge-colorSecondary {
		background-color: grey;
	}
	& li {
		display: grid;
		grid-gap: 2rem 1rem;
		grid-template-columns: 60px repeat(2, auto);
		align-items: center;
		padding: 1rem 0;
		& > span:nth-child(2) {
			justify-self: start;
		}
		& > span:nth-child(3) {
			justify-self: end;
		}
	}
	& > form {
		display: grid;
		grid-gap: .5rem;
		grid-template-columns: 1fr auto;

		& > input:nth-child(1) {
			display: inline-block;
			width: 100%;
			padding: .5rem 1rem;
			border-radius: 5px;
			border: 1px solid #ccc;
		}
		& > input:nth-child(2) {
			display: inline-block;
		}
	}
	& .cart-info-subtotal {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: repeat(2, 1fr);
		& > span:nth-child(1), span:nth-child(3), span:nth-child(5) {
			justify-self: start;
		}
		& > span:nth-child(2) {
			justify-self: end;
		}
		& > span:nth-child(4), span:nth-child(6) {
			justify-self: end;
			font-size: 12px;
		}
	}
	& .cart-info-total {
		display: grid;
		grid-gap: .5rem;
		grid-template-columns: 1fr repeat(2, auto);
		align-items: center;
		& > span:nth-child(1) {
			justify-self: start;
		}
		& > span:nth-child(2) {
			font-size: 12px;
		}
		& > span:last-child {
			font-size: 20px;
		}
	}
}

.form-submit {
	height: 42px;
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(2, auto);
	& > a {
		color: #333;
		align-self: center;
		justify-self: start;
		font-size: 12px;
		text-decoration: none;
	}
}

@media (max-width: 768px) {
	.express-checkout {
		grid-template-columns: 1fr !important;
		grid-template-rows: repeat(3, 50px);
	}
}