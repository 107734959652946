.subscribe-modal {
	position: absolute;
	top: 50vh;
	left: 50vw;
	transform: translate(-50%, -50%);
    z-index: 25;
    background-color: #000;
    color: #FFF;
    min-width: 300px;
    min-height: 300px;
    max-height: 75vh;
    border-radius: 5px;
    padding: 3rem 2.5rem;
    .icon-close {
        position: absolute;
        padding: 10px;
        top: 10px;
        right: 15px;
        color: #FFF;
        cursor: pointer;
    }
    form {
        width: 100%;
        display: grid;
        grid-template-rows: repeat(5, auto);
        grid-gap: 1em;
        justify-items: stretch;
        text-align: center;
        :first-child {
            font-size: 30px;
        }
		input:first-of-type {
			padding: .25rem 1rem;
		}
		input:last-of-type {
			padding: .5rem 1rem;
			background: #000;
			color: #fff;
			border: 1px solid #eee;
			border-radius: 2px;
			&:hover {
				background: #333;
			}
		}
        :last-child {
            font-size: 10px;
            opacity: 0.5;
        }
    }
}