.shipping {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 3fr 2fr;
	@media (max-width: 768px) {
		grid-template-columns: minmax(auto, 532px);
		justify-content: center;
		grid-template-rows: auto auto;
	}
	& .shipping-panel {
		display: grid;
		grid-gap: 1rem;
		& > span:nth-child(1) {
			justify-self: start;
			font-size: 12px;
		}
		& .shipping-info {
			border: 1px solid #ccc;
			border-radius: 5px;
			padding-block: .5rem;
			display: grid;
			align-items: center;
			grid-template-columns: minmax(auto, 100px) 1fr minmax(auto, 100px);
			font-size: 14px;
			& hr {
				grid-column: span 3;
				height: 1px;
				width: 90%;
				justify-self: center;
				margin: 0;

			}
			& > a {
				text-decoration: none;
				color: #ccc;
				font-size: 12px
			}
		}
		& .shipping-method {
			display: grid;
			& > span:nth-child(1) {
				justify-self: start;
			}
			& .shipping-items {
				border: 1px solid #ccc;
				border-radius: 5px;
				display: grid;
				align-items: center;
				grid-template-columns: 1fr;
				li {
					display: grid;
					padding: 0 .5rem;
					grid-template-columns: auto 1fr auto;
				}
			}
		}
	}
}
