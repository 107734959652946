.returns {
    p {
        text-align: left;
    }
    ul {
        list-style: disc;
    }
    li {
        margin-left: 3rem;
        text-align: left;
    }
}