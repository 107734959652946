$pink: #FFD9D2;

.footer {
    padding: 2em 2em;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 200px));
    justify-content: center;
    align-items: start;
    grid-gap: 1em;
    // border-top: 1px solid #e9e9e9;
    & a {
        text-decoration: none !important;
        color: #333;
    }
}
.copyright {
    text-align: center;
    padding-block: 1.3em;
    background-color: $pink;
    opacity: .6;
    font-size: 0.75em;
}
