$pink: #FFD9D2;

.cart {
	& hr {
		background-color: #333;
		margin-block: 1.5rem;
	}
}

.cart-legend {
	display: grid;
	grid-template-columns: minmax(auto, 250px) minmax(auto, 200px);
	justify-content: end;
	font-size: 20px;
	font-weight: 900;
	letter-spacing: .1rem;
	@media (max-width: 768px) {
		display: none;
	}
}

.cart-items {
	& li {
		display: grid;
		grid-gap: 2rem;
		grid-template-columns: 150px auto minmax(auto, 200px) minmax(auto, 200px);
		align-items: center;
		padding-block: 1rem;
		@media (max-width: 768px) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
		}
		& img {
			border-radius: .25rem;
		}
		& > span:nth-of-type(1) {
			font-weight: 900;
			font-size: 20px;
			font-style: italic;
		}
		& > div {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(2, 1fr);
			align-items: center;
			& > span {
				grid-row: span 2;
				justify-self: end;
				background-color: $pink;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50px;
				height: 40px;
				font-weight: 600;
			}
			& i {
				justify-self: start;
				padding-left: 1rem;
				cursor: pointer;
			}
			& > button {
				grid-column: span 2;
				margin-block: 1rem;
			}
		}
		& > span:nth-of-type(2) {
			font-weight: 600;
		}
	}
}

.cart-footer {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: 3fr 2fr;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto;
	}
	& div:nth-of-type(1) {
		display: grid;
		grid-auto-flow: row;
		& textarea {
			background-color: $pink;
			padding: 1rem;
			border: none;
		}
	}
	& div:nth-of-type(2) {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: minmax(auto, 250px) minmax(auto, 200px);
		grid-template-rows: auto auto auto auto 1fr;
		& > span:nth-child(-n+2) {
			font-size: 20px;
			font-weight: 900;
		}
		& > span:nth-child(n+3) {
			grid-column: span 2;
			justify-self: end;
		}
		& > form {
			grid-column: span 2;
			display: grid;
			grid-gap: .5rem;
			grid-template-columns: auto 1fr auto;
		}
		& > button {
			grid-column: span 2;
			justify-self: end;
		}
	}
}