.information {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 3fr 2fr;
	@media (max-width: 768px) {
		grid-template-columns: minmax(auto, 532px);
		justify-content: center;
		grid-template-rows: auto auto;
	}
	& .info-panel {
		display: grid;
		grid-gap: 1rem;
		& > span:nth-child(1) {
			justify-self: start;
			font-size: 12px;
		}
		& .express-checkout {
			position: relative;
			border: 1px solid #ccc;
			border-radius: 5px;
			padding: 1.5rem 1rem;
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: repeat(3, 1fr);
			& > button {
				border-radius: 5px;
			}
			& > div:nth-child(1) {
				position: absolute;
				top: -13px;
				left: calc(50% - 77.5px);
				background-color: #fff;
				padding-inline: 1rem;
			}
			& > button:nth-of-type(2) {
				background-color: #ffc439;
			}
		}
		& .or {
			border-top: 1px solid #ccc;
			position: relative;
			& > span {
				position: absolute;
				top: -13px;
				background-color: #fff;
				padding-inline: 1rem;
				margin-left: -1rem;
			}
		}
		& .contact-info-header {
			display: grid;
			grid-template-columns: 1fr auto;
			& > span:nth-child(1) {
				justify-self: start;
			}
			& > span:nth-child(2) {
				justify-self: end;
				align-self: center;
				font-size: .75rem;
				& > a {
					color: #333;
					text-decoration: none;
				}
			}
		}
		& .shipping-header {
			display: grid;
			justify-content: start;
			padding-block: 1.5rem 1rem;
		}
		& input {
			width: 100%;
			padding: .5rem 1rem;
			border-radius: 5px;
			border: 1px solid #ccc;
		}
		& .shipping-address {
			display: grid;
			grid-gap: .5rem;
			.address_name {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-gap: .5rem;
				@media (max-width: 768px) {
					grid-template-columns: 1fr;
				}
			}
			.address_area {
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-gap: .5rem;
				@media (max-width: 768px) {
					grid-template-columns: 1fr;
				}
			}
			// grid-template-columns: repeat(2, auto);
			// > div:first-child,
			// > input:nth-child(6), 
			// > input:nth-child(7), 
			// > input:nth-child(10),
			// > input:nth-child(11) {
			// 	grid-column: span 2;
			// }
			& > a {
				color: #333;
				align-self: center;
				justify-self: start;
				font-size: 12px;
				text-decoration: none;
			}
			& > .form-submit {
				// grid-column: span 2;
			}
			.react-tel-input {
				// grid-column: span 2;
				input {
					padding-left: 50px;
				}
			}

		}
	}
}

