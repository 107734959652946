.carousel {
    top: -65px;
    height: calc(100vw * .515);
    min-height: 535px;
    margin-bottom: -65px;
    & .carousel-item {
        height: calc(100vw * .515);
        min-height: 535px;
        background: no-repeat center top scroll;
        background-size: contain;
        @media (max-width: 1015px) {
            background-size: cover;
        }
    }
    & .carousel-caption {
        padding-block: 10%;
        & h3 {
            color: #000;
            font-size: calc(100vw * .05);
        }
    }
    & button {
        padding: 0.5em 1em;
        border: none;
        color: #FFF;
        background-color: #000;
        justify-self: center;
        & a {
          text-decoration: none;
          color: #FFF;
        }
    }
}
.home-h3 {
    font-weight: 900;
}
.collection-items {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: repeat(2, minmax(220px, 1fr));
	padding: 2rem 1rem;
    font-size: 24px;
    font-weight: bold;
    align-items: center;
    
    img:nth-child(1) {
        order: 1;
    }
    p:nth-child(2) {
        order: 2;
    }
    img:nth-child(3) {
        order: 4;
    }
    p:nth-child(4) {
        order: 3;
    }
    img:nth-child(5) {
        order: 5;
    }
    p:nth-child(6) {
        order: 6;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        img:nth-child(3) {
            order: 3;
        }
        p:nth-child(4) {
            order: 4;
        }
    }
}