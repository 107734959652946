.product-details {
	display: grid;
	justify-items: center;
	grid-template-columns: 350px 1fr;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-auto-flow: column;
		grid-template-rows: repeat(4, auto);
	}
	.product-images > div:first-child {
		width: 350px;
		img {
			object-fit: contain;
		}
	}
	 .product-image-thumb {
		display: grid;
		grid-auto-rows: 1fr;
		grid-gap: 1rem;
		padding-block: 1rem;
		max-width: 350px;
		grid-auto-rows: 1fr;
		grid-template-columns: repeat(auto-fit, minmax(90px, auto));
		& img {
			width: 100%;
			height: 100%;
			max-height: 171px;
			object-fit: cover;
		}
	}
	& .product-info {
		padding: 2rem;
		& hr {
			width: 50px;
			margin-inline: auto;
			background: #000;
		}
		& button {
			margin-block: 2rem 4rem;
		}
		& ul {
			text-align: left;
			padding: 1rem;
			list-style: disc;
		}
	}
}
.product-review-title {
	padding: 1rem;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	button {
		margin-left: auto;
	}
}
.product-review {
	grid-column: span 2;
	display: grid;
	grid-gap: 1rem;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(auto, 163px));
}

.write-review {
	display: grid;
	grid-column-gap: .5rem;
	grid-template-columns: 3fr 2fr;
	padding-inline: 1rem;
	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		textarea {
			height: 100px;
		}
		input[name='user'],
		input[type='file'],
		.rating,
		button {
			margin-block: 1rem;
		}
	}
	& .rating {
		justify-self: center;
	}
	& textarea {
		padding: .5rem;
		display: block;
		width: 100%;
		grid-row: span 3;
	}
	& input[name="user"] {
		display: block;
		width: 100%;
		padding-inline: .5rem;
	}
	& input[type="file"] {
		display: block;
		width: 100%;
		margin-block: .5rem;
	}
	& button {
		width: 200px;
		justify-self: center;
	}
	& label[for="description"] {
		grid-row: 4;
		font-size: 10px;
		justify-self: right;
	}
}